<template>
  <div>
    <div class="header-site">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black">
          Back
        </b-button>
      </router-link>
      <h1>Image Upload</h1>
    </div>

    <vue-dropzone
      ref="myDropzone"
      id="my-dropzone"
      :options="dropzoneOptions"
      @vdropzone-success="onUploadSuccess"
    />
    <div v-if="mediaUrls.length" class="media-url">
      <p>Uploaded Image URL(s):</p>
      <ul class="list-unstyled">
        <li v-for="(url, idx) in mediaUrls" :key="idx">
          <a :href="url" target="_blank">{{ url }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "ImageUpload",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      mediaUrls: [],
      dropzoneOptions: {
        url: `${this.$api._URL}/media/v2/siteID/dashboard`,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        maxFiles: null,
        acceptedFiles: ".png, .gif, .jpeg, .jpg",
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i> Drag & drop images or click to upload.",
        headers: {
          "Cache-Control": null,
          "X-Requested-With": null,
          Authorization: this.$api.getAuthorizationHeader(),
        },
      },
    };
  },
  methods: {
    onUploadSuccess(file, response) {
      const url = `https://media.rocketalumnisolutions.com/${response.uuid}/-/resize/1920x/`;
      this.mediaUrls.push(url);
    },
  },
};
</script>

<style scoped>
.header-site {
  padding: 20px;
  background-color: #133353;
  color: white;
}

.list-unstyled {
  list-style-type: none;
  text-align: left;
  padding-inline: 50px;
}

.media-url {
  margin-top: 20px;
  font-size: 16px;
}

.media-url a {
  color: #133353;
  text-decoration: underline;
}
</style>